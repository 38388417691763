import request from "@/utils/request";
const name = "/aimber";

//发布作品
export function InsertProject(data) {
  return request({
    url: name + "/art/projects",
    method: "post",
    data,
  });
}

//编辑作品
export function UpdateProject(data) {
  return request({
    url: name + "/art/projects",
    method: "PUT",
    data,
  });
}


//获取软件列表
export function GetAllSoftware(data) {
  return request({
    url: name + "/art/softwares/all",
    method: "get",
    params: data,
  });
}

//管理作品列表
export function GetProjectsPageable(data) {
  return request({
    url: name + "/art/projects/pageable",
    method: "post",
    data,
  });
}

//删除作品
export function DeleteProject(data) {
  return request({
    url: name + "/art/projects/" + data,
    method: "DELETE",
  });
}

//获取项目统计信息
export function ProjectStatistics(data) {
  return request({
    url: name + "/art/projects/statistics",
    method: "get",
    params: data,
  });
}


//作品详情
//获取自己的作品信息
export function ProjectById(data) {
  return request({
    url: name + "/art/projects/" + data,
    method: "get",
  });
}

//获取作品信息
export function Projects(data) {
  return request({
    url: name + "/public/projects/" + data,
    method: "get",
  });
}

//作品点赞
export function VoteProject(data) {
  return request({
    url: name + "/art/projects/vote",
    method: "post",
    data,
  });
}

//作品取消点赞
export function UnvoteProject(data) {
  return request({
    url: name + "/art/projects/unvote",
    method: "post",
    data,
  });
}

//作品评论点赞
export function VoteComment(data) {
  return request({
    url: name + "/art/projects/comments/vote",
    method: "post",
    data,
  });
}

//作品评论取消点赞
export function UnvoteComment(data) {
  return request({
    url: name + "/art/projects/comments/unvote",
    method: "post",
    data,
  });
}

//作品评论取消点赞
export function DeteleCommentBySelf(data) {
  return request({
    url: name + "/art/projects/comments",
    method: "DELETE",
    data,
  });
}

//作品评论取消点赞
export function MoreProjectsByAuthorId(data) {
  return request({
    url: name + "/public/projects/more-projects",
    method: "POST",
    data,
  });
}

//作品浏览量
export function IncreaseProjectViewCount(data) {
  return request({
    url: name + "/public/projects/view/"+data,
    method: "post",
  });
}
//作品评论列表
export function ProjectComment(data) {
  return request({
    url: name + "/public/projects/comments",
    method: "post",
    data,
  });
}

//作品评论
export function InsertComment(data) {
  return request({
    url: name + "/art/projects/comments",
    method: "post",
    data,
  });
}

//按照作者id获取项目列表
export function ProjectsByAuthorId(data) {
  return request({
    url: name + "/public/projects/pageable",
    method: "post",
    data,
  });
}

//获取作者点赞项目列表
export function VotedProjectsByAuthor(data) {
  return request({
    url: name + "/public/projects/voted-project",
    method: "post",
    data,
  });
}
export function UpdateUserProfile(data) {
  return request({
    url: name + "/user-profile/update",
    method: "PUT",
    data,
  });
}

export function GetUserBlackList(data) {
  return request({
    url: name + "/user-profile/black-list",
    method: "post",
    data,
  });
}

//首页作品列表/时间
export function ProjectsByTimeDesc(data) {
  return request({
    url: name + "/public/home/pageable-by-time",
    method: "post",
    data,
  });
}

//首页作品列表/智能排序
// export function ProjectsByAutoCalculate(data) {
//   return request({
//     url: name + "/public/home/pageable-calculate",
//     method: "post",
//     data,
//   });
// }

export function ProjectsByAutoCalculate(data) {
  return request({
    url: name + "/public/home/pageable-category",
    method: "post",
    data,
  });
}

//首页获取关注人作品
export function FolloweeProjectsPageable(data) {
  return request({
    url: name + "/art/projects/pageable-by-followee",
    method: "post",
    data,
  });
}

//获取作品分类
export function ProjectCategoryAll(data) {
  return request({
    url: name + "/public/projects/category",
    method: "get",
    params: data,
  });
}