import router from "./router";
let hostname = window.location.hostname;
let mobileDomain, desktopDomain;
const userAgent = navigator.userAgent;
const screenWidth = window.innerWidth;
let isMobile; //手机
let isTablet; //平板

if (/iPad|Android/.test(userAgent)) {
  isTablet = true;
  isMobile = false;
} else if (/Mobi|Android/.test(userAgent) || screenWidth < 768) {
  isMobile = true;
  isTablet = false;
} else {
  isMobile = false;
  isTablet = false;
}
// const isMobile = () => /mobile/i.test(navigator.userAgent);
let searchString = ["/login", "/workDetails", "/share"];
// const mobileDomain = "http://192.168.0.19:8080";
// const desktopDomain = "http://192.168.0.19:8081";
if (hostname == "192.168.0.110") {
  mobileDomain = "http://192.168.0.110:8889"; //h5
  desktopDomain = "http://192.168.0.110:8888"; //pc
} else if (hostname == "aimber.cn") {
  mobileDomain = "https://m.aimber.cn";
  desktopDomain = "https://aimber.cn";
} else {
  mobileDomain = "http://192.168.0.19:8081"; //h5
  desktopDomain = "http://192.168.0.19:8080"; //pc
}

router.beforeEach(async (to, from, next) => {
  if (isTablet || isMobile) {
    // 在移动设备上，跳转到移动域名
    if (window.location.origin !== mobileDomain) {
      // next({ path: mobileDomain + to.fullPath })
      if (searchString.includes(to.path)) {
        window.location.href = mobileDomain + to.fullPath;
      } else {
        window.location.href = mobileDomain;
      }
      next();
      // next(mobileDomain + to.fullPath);
    } else {
      next();
    }
  } else {
    // 在桌面设备上，跳转到桌面域名
    if (window.location.origin !== desktopDomain) {
      // window.location.href = desktopDomain + to.fullPath;
      if (searchString.includes(to.path)) {
        window.location.href = desktopDomain + to.fullPath;
      } else {
        window.location.href = desktopDomain;
      }
      next();
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
});
