<template>
  <div id="app" class="appbox">
    <ul class="menu-level-1 " v-if="headtype" :class="specialPage ? '' : 'menu-level-share'">
      <div class="flexbox" style="gap: 60px;">
        <!-- <router-link to="/" class="index-box">
          <img class="logo" src="./assets/logo.png" alt="" />
        </router-link> -->
        <div class="index-box" @click="getJump(10)">
          <img class="logo" src="./assets/logo.png" alt="" />

        </div>
        <div class="flexbox" style="gap: 28px;" v-if="specialPage">
          <div class="tab-item" :class="activeIndex != index ? '' : 'active'" v-for="(item, index) in tabs" :key="index"
            @click="getBtn(index)">{{ item }}</div>
        </div>
      </div>

      <div class="share-search">
        <div class="flexbox searchbox" v-if="specialPage">
          <el-dropdown trigger="hover" ref="search">
            <span>
              <el-input @keyup.enter.native="getSearch('1')" maxlength="20" class="flex-grow-1" placeholder="搜索画师、作品"
                v-model="searchInput">
              </el-input>
              <img class="searc_icon" src="./assets/common/searc_icon.png" alt="" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item v-if="noticeList != ''">
              <div v-for="(item, index) in noticeList" :key="index" class="notice-item"
                @click="getNotice(index, item.id)">
              </div>
            </el-dropdown-item> -->
              <div class="s-tabs-box">
                <div class="s-tab-btn" @click="getSearch('0')">
                  <span class="s-tisp">搜索</span><span>画师</span>
                </div>
                <div class="s-tab-btn" @click="getSearch('1')">
                  <span class="s-tisp">搜索</span><span>作品</span>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="menu-level-1-buttons index-box">
          <template v-if="specialPage">
            <div class="gift-icon" @click="getJump(6)">
              <img class="deliver" src="./assets/nav/deliver.png" alt="" @click="getJump(6)">
            </div>

            <div class="upload-icon" @click="getRelease"></div>
          </template>
          <template v-if="userInfo != ''">
            <span @click="allNotice" >
              <el-dropdown trigger="hover" ref="dropdownbtn" class="newsbox" v-if="specialPage">
              <el-badge :value="noticeCount || ''" class="item">
                <div class="news" @mouseenter="getMouseenter()"></div>
                <!-- <img class="news" src="./assets/nav/news.png" alt=""> -->
              </el-badge>
              <!-- <div class="news" v-else @mouseenter="getMouseenter()"></div> -->
              <el-dropdown-menu slot="dropdown">
                <span class="notice-title-box">
                  <div class="notice-title" :class="tabid != index ? 'notice-title-active' : ''"
                    v-for="(item, index) in 2" :key="index" @click="tabNotice(index)">{{ index == 0 ? "系统" : "互动" }}
                  </div>
                </span>
                <el-dropdown-item v-if="noticeList[tabid] != ''">
                  <div v-for="(item, index) in noticeList[tabid]" :key="index" class="notice-item" @click="goJump(item)"
                    :style="item.readStatus == 1 ? 'color:#999999' : 'color:#ffffff'
                      ">
                    <template>
                      <div class="notice-item-content flexbox">
                        <!-- <div class="notice-item-info">
                        【{{ item.category ? "系统" : "互动" }}】
                      </div> -->
                        <template v-if="
                          item.subCategory == 0 ||
                          item.subCategory == 1 ||
                          item.subCategory == 2 ||
                          item.subCategory == 3
                        ">
                          <div class="notice-item-info notice-item-name work-reply">
                            {{ item.objectContent }}
                          </div>
                          <div v-if="item.subCategory == 0" class="notice-item-info">
                            收到{{ item.objectCount }}个新点赞
                          </div>
                          <div v-if="item.subCategory == 1" class="notice-item-info">
                            有{{ item.objectCount }}条新评论
                          </div>
                          <div v-if="item.subCategory == 2" class="notice-item-info">
                            有{{ item.objectCount }}条新回复
                          </div>
                          <div v-if="item.subCategory == 3" class="notice-item-info">
                            收到{{ item.objectCount }}个新点赞
                          </div>

                        </template>
                        <template v-if="item.subCategory == 5">
                          <div class="notice-item-info notice-item-name">
                            有 {{ item.objectCount }}人 关注了你
                          </div>
                        </template>
                        <template v-if="item.subCategory == 4">
                          <div class="notice-item-info notice-item-name work-reply">
                            {{ item.objectContent }}
                          </div>
                          <div class="notice-item-info">Aimber已回复</div>
                        </template>
                        <template v-if="
                          item.subCategory == 6 ||
                          item.subCategory == 7 ||
                          item.subCategory == 8
                        ">
                          <div class="notice-item-info notice-item-name">
                            用户资料涉及违规内容，请修改
                          </div>
                        </template>
                        <template v-if="
                          item.subCategory == 9 ||
                          item.subCategory == 10
                        ">
                          <div class="notice-item-info notice-item-name">
                            {{ item.subCategory == 9 ? '受邀获赠15琥珀。' : '邀新成功，获赠15琥珀。' }}可为15件作品提供保护
                          </div>
                          <div class="notice-item-info" v-if="item.subCategory == 9">去上传</div>
                        </template>
                        <template v-if="item.subCategory == 11">
                          <div class="notice-item-info notice-item-name">
                            恭喜首次完成保护，赠送您电子版存证证书，保护看得见<span class="notice-item-info">立即体验</span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </el-dropdown-item>
                <div class="notice-nothing" v-else>暂无</div>

                <div class="all-notice" @click="allNotice">
                  查看全部通知 <span v-if="noticeList != ''">({{ total }})</span>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            </span>
            
            <!-- 多语言 -->
            <!-- <li><lang-select class="set-language" :color_type='1' /></li> -->
            <el-dropdown trigger="hover">
              <Avatar :type="2" @click="getJump(5)" v-if="userInfo" :userId="userInfo.uid"
                :imgUrl="userInfo.userDetailVO.avatarUrl" :size="36">
              </Avatar>

              <!-- <el-avatar size="medium" :src="userInfo.userDetailVO.avatarUrl"></el-avatar> -->
              <el-dropdown-menu slot="dropdown">
                <div class="user-info" @click="getJump(5)">
                  <!-- <el-avatar size="medium" :src="userInfo.userDetailVO.avatarUrl"></el-avatar> -->
                  <Avatar v-if="userInfo" :userId="userInfo.uid" :imgUrl="userInfo.userDetailVO.avatarUrl" :size="36">
                  </Avatar>
                  <div class="user-info-text">
                    <div class="user-info-name" style="">
                      {{ userInfo.userDetailVO.nickName }}
                    </div>
                  </div>
                </div>
                <el-dropdown-item>
                  <div class="menu-item menu-item-share" @click="getJump(6)">
                    <img class="menu-item-icon" src="./assets/nav/menu/share.png" alt="" />
                    <div class="menu-item-name">推荐Aimber给朋友！</div>
                  </div>
                  <div v-for="(item, index) in menulist" :key="index" class="menu-item"
                    @click="getJump(index, item.id)">
                    <i class="menu-item-icon" :class="item.icon"></i>
                    <!-- <img class="menu-item-icon" :src="item.img" alt=""> -->
                    <div class="menu-item-name">{{ item.name }}</div>
                  </div>
                </el-dropdown-item>

                <div class="logouts" @click="logout">退出登录</div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <div v-else class="flexboxs release upload" @click="gologin">登录/注册</div>
        </div>
      </div>

    </ul>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"
        :style="currentPath == '/login' || currentPath == '/countdown' || currentPath == '/share' || currentPath == '/purchase' ? '' : 'padding-top: 64px'" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"
      :style="currentPath == '/login' || currentPath == '/countdown' || currentPath == '/share' || currentPath == '/purchase' ? '' : 'padding-top: 64px'" />

    <div class="footer-boxs" v-if="customValue">
      <div class="flexbox">
        <div class="left-box">
          <img class="logos" src="./assets/logo.png" alt="" />
          <div class="footer-text">为画师提供创作灵感与版权保护</div>
        </div>
        <div class="right-box">
          <div style="padding-right: 88px;">
            <div class="agreement">网站协议</div>
            <div class="agreement-text">
              <span @click="goicp(index)" :style="index % 2 == 0 ? 'padding-right: 8px;' : ''"
                v-for="(item, index) in agreement" :key="index">{{
                  item
                }}</span>
            </div>
          </div>
          <div style="display: flex;">
            <div>商务合作</div>
            <div class="contact">
              <img class="weixin" src="./assets/weixin.jpg" alt="">
              <div>business@aimber.cn</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span>顿年科技（成都）有限公司</span>
        <span>©</span>
        <span style="padding-right: 16px">2024</span>
        <span class="icp" @click="goicp(4)" style="padding-right: 80px">蜀ICP备2024078978号-1</span>
        <div @click="goicp(5)" class="flexboxs">
          <img class="icon-g" style="" src="@/assets/countdown/gh.png" alt="">
          <span class="icp">川公网安备51019002006580</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

import LangSelect from "@/components/LangSelect";
import { getUser, getWithExpiry } from "@/utils/auth";
import Avatar from "@/components/Avatar/index";
import Tucao from "@/utils/tucao";
import { Toast } from "vant";
import success from "@/assets/common/success.png";
import { UserLogout } from "@/api/login";
import { NotificationCount, NotificationPageable } from "@/api/notification";
import { } from "@/api/work";
export default {
  components: { LangSelect, Avatar },
  data() {
    return {
      tabs: ['首页', '作品'],
      activeIndex: 0,
      searchInput: "",
      headtype: true,
      currentPath: "/login",
      menulist: [
        {
          name: "管理作品",
          img: require("./assets/nav/menu/manage.png"),
          icon: "icon-manage",
          id: 0,
        },
        {
          name: "保护记录",
          img: require("./assets/nav/menu/protect.png"),
          icon: "icon-protect",
          id: 1,
        },
        {
          name: "编辑资料",
          img: require("./assets/nav/menu/edit.png"),
          icon: "icon-edits",
          id: 2,
        },
        {
          name: "设置",
          img: require("./assets/nav/menu/setup.png"),
          icon: "icon-setup",
          id: 3,
        },
        {
          name: "建议Aimber",
          img: require("./assets/nav/menu/proposal.png"),
          icon: "icon-proposal",
          id: 4,
        },
      ],
      agreement: [
        '隐私政策',
        '用户协议',
        '作品公约',
        '了解技术',
      ],
      noticeList: [[], []],
      routerList: [
        "/editInfo/manageWorks",
        "/editInfo/protectRecord",
        "/editInfo/editData",
        "/editInfo/setUp",
        "/editInfo/blacklist",
        "/userHomepage",
      ],
      userInfo: "",
      noticeCount: 0,
      total: 0,
      logState: this.publics.onToken(), //登录
      loading: "",
      time: Date.parse(new Date()),
      appointTime: Date.parse(new Date('2024-08-06 07:00:00')),
      background: this.$store.getters.background,
      time: null,
      tabid: 0,
      specialPage: true,
      customValue: ''
    };
  },
  created() {
    if (getUser() != undefined) {
      this.userInfo = JSON.parse(getUser());
    }
    this.time = setInterval(() => {
      if (getUser() != undefined) {
        this.getNotice();
        clearInterval(this.time);
      }
    }, 1000);
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        this.currentPath = route.path;
        this.customValue = route.meta.customValue;
        const time = Date.parse(new Date())
        if (time < this.appointTime) {
          if (time < this.appointTime) {
            this.$router.push({ path: "/countdown" });
          }
          else {
            this.$router.push({ path: "/", });
          }
        }
        else {
          if (route.path == "/countdown") {
            this.$router.push({ path: "/", });
          }
        }

        if (route.path == "/login" || route.path == "/countdown") {
          this.headtype = false;
        } else {
          this.headtype = true;
        }
        if (route.path == "/share" || route.path == "/purchase") {
          this.specialPage = false;
          document.documentElement.style.setProperty('--background', 0);

        } else {
          this.specialPage = true;
        }

      },
    },
    "$store.state.user.userInfo": {
      handler: function (newVal, oldVal) {
        // this.loginShow = newVal
        this.userInfo = JSON.parse(newVal);
      },
    },
    "$store.state.app.background": {
      handler: function (newVal, oldVal) {
        // this.loginShow = newVal
        document.documentElement.style.setProperty('--background', newVal);
      },
    },
    "$store.state.operation.activeIndex": {
      handler: function (newVal, oldVal) {
        this.activeIndex = newVal
      },
    },
  },
  mounted() {
    this.activeIndex = this.$store.state.operation.activeIndex
  },
  methods: {
    goicp(type) {
      if (type == 4) {
        window.open("https://beian.miit.gov.cn/#/Integrated/index");
      }
      else if (type == 5) {
        window.open("https://beian.mps.gov.cn/#/query/webSearch");
      }
      else {
        this.$router.push({
          path: "/protocol",
          query: {
            tabsId: type + 1,
          },
        });
      }
    },
    getBtn(index) {
      this.activeIndex = index
      this.$store.commit("operation/SET_ACTIVEINDEX", index);
      if (index == 0) {
        this.$router.push({ path: '/' });

      }
      else {
        this.$router.push({ path: '/works' });
      }
    },

    tabNotice(index) {
      this.tabid = index
    },
    //通知统计
    getNotice() {
      NotificationCount()
        .then((res) => {
          if (res.code == 200) {
            this.noticeCount = res.data;
            setTimeout(() => {
              this.getNotice();
            }, 2000);
          }
        })
        .catch((reject) => {
          console.log("reject", reject);
        });
    },
    //通知列表
    getNoticeList() {
      let params = {
        current: 1,
        size: 5,
        records: ["false"],
      };
      this.noticeList = [[], []]
      NotificationPageable(params)
        .then((res) => {
          if (res.code == 200) {
            // this.noticeList = res.data.records;
            this.total = res.data.total;
            res.data.records.forEach((item) => {
              if (item.category == 0) {
                this.noticeList[1].push(item)
              } else if (item.category == 1) {
                this.noticeList[0].push(item)
              }
            })
          }
        })
        .catch((reject) => {
          console.log("reject", reject);
        });
    },
    goJump(item) {
      Cookies.set('closePath', this.$route.fullPath)
      this.$router.push("/myNotice");
      return
      if (
        item.subCategory == 0 ||
        item.subCategory == 1 ||
        item.subCategory == 2 ||
        item.subCategory == 3
      ) {
        this.$router.push({
          path: "/workDetails",
          query: {
            workId: item.projectId,
          },
        });
      } else if (item.subCategory == 5) {
        this.$router.push({
          path: "/userHomepage",
          query: {
            uid: item.userId,
          },
        });
      } else if (item.subCategory == 4) {
        this.$router.push({
          path: "/workOrderDetails",
          query: {
            id: item.ticketId,
          },
        });
      } else if (
        item.subCategory == 6 ||
        item.subCategory == 7 ||
        item.subCategory == 8
      ) {
        this.$store.commit("operation/SET_ID", 2);
        this.$router.push({
          path: "/editInfo/editData",
          query: {
            uid: item.userId,
          },
        });
      }
      else if (
        item.subCategory == 9 ||
        item.subCategory == 10 ||
        item.subCategory == 11
      ) {
        this.$router.push("/myNotice");
      }
    },
    getMouseenter() {
      this.getNoticeList();
    },
    getMouseleave() {
      // this.id=index
      this.show = false;
    },
    getSearch(searchType) {
      if (this.searchInput != "") {
        this.$refs.search.hide();
        this.$store.commit(
          "operation/SET_SEARCH",
          JSON.stringify({
            searchType: searchType,
            searchInput: this.searchInput,
          })
        );
        this.$router.push({
          name: "search",
          params: { searchType: searchType, searchInput: this.searchInput },
        });
      }
    },
    // 全部通知
    allNotice() {
      this.$router.push("/myNotice");
      this.$refs.dropdownbtn.hide();
    },

    //去登录
    gologin() {
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    clearAllCookies() {
      // 获取所有cookies
      const cookies = document.cookie.split("; ");
      // 遍历并清除
      for (const cookie of cookies) {
        const equalIndex = cookie.indexOf("=");
        // 设置过期时间为过去的时间
        document.cookie = `${cookie.slice(
          0,
          equalIndex
        )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    },
    //退出登录
    async logout() {
      UserLogout()
        .then((responses) => {
          this.clearAllCookies();
          localStorage.clear();
          this.$store.commit("user/SET_TOKEN", "");
          this.$router.push("/login");
          setTimeout(() => {
            window.location.reload();
          }, 20);
        })
        .catch((reject) => {
          console.log("reject", reject);
        });
    },
    //复制链接
    copyUrl() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", "https://aimber.cn/#/");
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        document.body.removeChild(input);
      }
      Toast({
        message: "复制成功",
        icon: success,
      });
    },
    //跳转页面
    getJump(index, id) {
      let user = this.userInfo;
      if (index == 10) {
        this.getBtn(0)
      }
      else if (index == 6) {
        this.$router.push({ path: '/share' });
      }
      else {
        if (
          getWithExpiry() != null &&
          getWithExpiry() != undefined &&
          getUser() != undefined
        ) {
          if (index == 5) {
            this.$router.push({
              path: this.routerList[index],
              query: { uid: this.userInfo.uid },
            });
          }
          else {
            if (id < 4) {
              this.$store.commit("operation/SET_ID", id);
              this.$router.push({
                path: this.routerList[index],
                query: { uid: this.userInfo.uid },
              });
            } else {
              var data = {
                // nickname,avatar,openid 必填
                nickname: user.userDetailVO.nickName,
                avatar: user.userDetailVO.avatarUrl,
                openid: user.uid,
              },
                productId = 647361; // 把1221数字换成你的产品ID，否则会不成功
              Tucao.request(productId, data);
            }
          }
        } else {
          this.gologin();
        }
      }
    },
    getRelease() {
      if (
        getWithExpiry() != null &&
        getWithExpiry() != undefined &&
        getUser() != undefined
      ) {
        this.$router.push(
          "/workUpload",
          () => { },
          () => { }
        );
        return
        if (Cookies.get('workId')) {
          window.location.reload();
        } else {
          this.$router.push(
            "/workUpload",
            () => { },
            () => { }
          );
        }
      } else {
        this.gologin();
      }
    },

    // guide() {
    //   this.$router.push(`/guide`, () => { }, () => { })
    // },
  },

};
</script>

<style lang="scss">
@import "./styles/icon.css";
@import "./styles/media.scss";

.createLoading {
  .el-loading-spinner {
    .el-icon-loading:before {
      background: url("./assets/common/lod.webp");
      background-size: 100% 100%;
      content: "\0000a0";
      display: inline-flex;
      height: 44px;
      width: 44px;
    }

    .el-loading-text {
      color: var(--hover-btn-color) !important;
    }
  }
}



// @include button-basic;
.flexbox {
  display: flex;
  align-items: center;
}

.flexboxs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-release-img {
  @include wh(24px, 24px);
}

.u-img-item-close {
  background: url("@/assets/common/erasure.png") no-repeat;
  background-size: 100% 100%;

  &:hover {
    background: url("@/assets/common/erasurehover.png") no-repeat;
    background-size: 100% 100%;
  }
}

/* 自定义整个滚动条 */
::-webkit-scrollbar {
  overflow-x: scroll;
  scrollbar-width: none;
  /* 隐藏标准滚动条（仅适用于较新的浏览器）*/
  width: 0px;
  /* 设置滚动条的宽度 */
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  // background: #f1f1f1;
  /* 设置轨道的背景颜色 */
}

/* 自定义滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background: #414141;
  border-radius: 8px;
  /* 设置滑块的背景颜色 */
}

/* 当滑块悬停或活动时，可以添加更多样式 */
::-webkit-scrollbar-thumb:hover {
  background: #575757;
  /* 设置滑块在悬停状态下的背景颜色 */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #ffffff;
  // color: #2c3e50;
}

html {
  background: var(--primary-background-color);
}

html,
body,
ul {
  margin: 0;
  padding: 0;
}

li::marker {
  content: "";
}

.el-avatar {
  background: none !important;
}

.menu-level-1 {
  align-items: center;
  background: var(--nav-background-color);
  display: flex;
  flex-direction: row;
  gap: 24px;
  height: 64px;
  justify-content: space-between;
  left: 0;
  max-height: 100vh;
  padding: 20px 48px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1032;
  box-sizing: border-box;

  .tab-item {
    @include button-basic-Light;
    color: #CDCDCD;
    cursor: pointer;
    min-width: 32px;
    &:hover{
      color: #ffffff;
    }
  }

  .active {
    color: #ffffff;

  }

  .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }

  // @media(max-width:1224px) {
  //   .share-search {
  //     display: none !important;
  //   }
  // }


  .share-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 28px;
  }

  .searchbox {
    // display: flex;
    // justify-content: center;
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: auto;
    width: 360px;

    .el-dropdown {
      width: 100%;
    }
  }

  .index-box {
    z-index: 999;

  }

  .logo {
    padding: 20px 0;
    width: 114px;
    height: 28px;
    cursor: pointer;
  }

  .flex-grow-1 {
    @include button-basic;
    color: #999999;
  }

  .searc_icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 12px;
    width: 16px;
    height: 16px;
  }

  .el-input {

    .el-input__inner {
      @include button-basic;
      color: #fff;
      width: 100%;
      height: 36px;
      border-radius: 4px;
      background: var(--secondary-background-color);
      border: none;
      padding-left: 40px;
    }

    :hover {
      background: var(--hover-background-color);
    }

    .el-input__inner::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #999999;
    }
  }

  .set-language {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .menu-level-1-buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 32px;
    height: 100%;
    justify-content: center;
    padding: 0;

    .gift-icon {
      margin-top: 6px;
      position: relative;
      cursor: pointer;
      @include wh(28px, 28px);
      background-image: url(./assets/nav/gift.png);
      background-size: 100% 100%;

      &:hover {
        background-image: url(./assets/nav/gift_hover.png);
      }
    }

    .upload-icon {
      margin-top: 6px;
      position: relative;
      cursor: pointer;
      @include wh(28px, 28px);
      background-image: url(./assets/nav/upload.png);
      background-size: 100% 100%;

      &:hover {
        background-image: url(./assets/nav/upload_hover.png);
      }
    }

    .deliver {
      @include wh(32px, 13px);
      position: absolute;
      right: -22px;
      top: -6px;
    }

    .release {
      background: #333333;
      width: 108px;
      height: 36px;
      line-height: 36px;
      border-radius: 4px;
      cursor: pointer;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background: var(--hover-secondary-btn-color);
      }
    }

    .upload {
      @include button-basic;
      color: var(--secondary-btn-color);
      // padding-left: 8px;
    }

    .news {
      margin-top: 6px;
      @include wh(28px, 28px);
      background-image: url(./assets/nav/news.png);
      background-size: 100% 100%;
    }

    .news:hover {
      background-image: url(./assets/nav/news_hover.png);
    }

    .item {
      // margin-top: 9px;
      cursor: pointer;
    }

    .el-badge__content {
      width: 18px;
      height: 18px;
      background: #df3232;
      border-radius: 58px 58px 58px 58px;
      padding: 3px;
      border: none;
      box-sizing: border-box;
    }

    .el-badge__content.is-fixed {
      position: absolute;
      top: 10px;
      right: 7px;
    }

    .el-badge__content {
      @include button-basic;
      font-size: 10px;
      color: #ffffff;
      line-height: 12px;
    }
  }
}

.menu-level-share {
  background: rgb(39, 39, 38, var(--background))
}

.el-avatar {
  @include wh(48px, 48px);
  cursor: pointer;
}

.logouts {
  @include button-basic-Light;
  cursor: pointer;
  color: #999999;
  padding-top: 4px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 16px;


}

.logouts:hover {
  color: #cdcdcd;
}

.e-edit-title {
  @include button-basic-Medium;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 32px;
}

.el-dropdown-menu--medium{
    padding: 0!important;
  }
  .el-popper[x-placement^=bottom]{
    margin-top: 4px !important;
  }
.el-dropdown-menu {
  background: #333333 !important;
  border-radius: 4px !important;
  border: none !important;
  // padding: 0 0 16px !important;
  color: #fff;
  z-index: 9998 !important;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.32) !important;

  .el-dropdown-menu__item {
    padding: 0 12px 12px !important;

  }

  .el-dropdown-menu__item i {
    margin-right: 0;
  }

  .el-dropdown-menu__item:hover {
    background: none !important;
    color: initial !important;
  }

  .user-info {
    @include flex;
    justify-content: initial;
    padding: 16px 24px 4px;
    cursor: pointer;

    .user-info-text {
      @include button-basic-Light;
      margin-left: 14px;
      font-size: 14px;

      .user-info-name {
        @include ellipsis_one;
        width: 138px;
        line-height: 16px;
      }

      .user-info-tis {
        color: #999999;
        line-height: 16px;
      }

      .user-info-tis:hover {
        color: #cdcdcd;
      }
    }
  }

  .menu-item {
    width: 204px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 14px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background: var(--hover-background-color);
    }

    .menu-item-icon {
      @include wh(14px, 14px);
    }

    .menu-item-name {
      @include button-basic-Light;
      color: #ffffff;
      padding-left: 8px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .notice-item {
    display: flex;
    align-items: center;
    margin: 4px 0;
    padding: 10px 8px;
    box-sizing: border-box;
    cursor: pointer;
    width: 336px;
    // height: 37px;
    border-radius: 4px;
    line-height: 14px;

    &:hover {
      background: var(--hover-background-color);
    }

    .notice-item-name {
      // @include ellipsis_one;
      // max-width: 140px;
    }

    .work-reply {
      @include ellipsis_one;
      max-width: 225px;
    }

    .notice-item-info {
      @include button-basic-Light;
      font-size: 12px;
      line-height: 14px;
      // color: #ffffff;
      padding-right: 8px;
    }
  }

  .notice-nothing {
    width: 396px;
    height: 40px;
    border-radius: 8px;
    @include button-basic-Light;
    text-align: center;
    line-height: 40px;
    padding: 12px 0;
  }

  .s-tabs-box {
    width: 360px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 12px;
    padding: 12px;

    .s-tab-btn {
      height: 28px;
      background: #333333;
      border-radius: 4px;
      border: 1px solid #999999;
      @include flex;
      cursor: pointer;
      padding: 0 12px;
      color: #ffffff;
      gap: 4px;
      @include button-basic;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 16px;

      .s-tisp {
        color: #cdcdcd;
      }

      &:hover {
        background: #414141;
      }
    }
  }

  .notice-title-box {
    @include flex;
    justify-content: left;
    // padding: 4px 0 12px;
    padding: 16px 30px 12px;
    gap: 40px;

    .notice-title {
      cursor: pointer;
      @include button-basic;
      font-size: 14px;
      line-height: 16px;
    }

    .notice-title-active {
      color: #999999;
    }
  }


  .all-notice {
    @include button-basic-Light;
    color: #CDCDCD;
    background: #333333;
    // text-align: center;
    cursor: pointer;
    // padding-top: 10px;
    width: 200px;
    height: 36px;
    margin: auto;
    border-radius: 4px;
    @include flex;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;

    &:hover {
      color: #cdcdcd;
      background: #414141;

    }
  }

  .menu-item-share {
    background: var(--primary-btn-color);
  }

  .menu-item-share:hover {
    background: var(--hover-btn-color);
  }

  .popper__arrow {
    position: initial !important;
    border-style: hidden !important;
  }

  .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border: none !important;
  }
}

.van-icon__image {
  width: 48px !important;
  height: 48px !important;
}

.van-toast {
  z-index: 9999 !important;
  padding: 0 !important;
  width: 191px !important;
  height: 158px !important;
  background: rgba(30, 30, 30, 0.73);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
    0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px 6px 6px 6px;
}

.van-toast__text {
  @include button-basic;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 22px;
  padding-top: 16px;
  margin-top: 0 !important;
}

.v-modal {
  background: #131313 64% !important;
}

.btn-box {
  gap: 24px;
  padding-top: 32px;

  .btn {
    width: 128px;
    height: 48px;
    border-radius: 8px 8px 8px 8px;
    @include flex;
    @include button-basic;
    color: #ffffff !important;
    line-height: 22px;
    cursor: pointer;
  }

  .cancel-btn {
    background: #333333;
  }

  .cancel-btn:hover {
    background: #414141;
  }

  .save-btn {
    background: var(--primary-btn-color) !important;
  }

  .save-btn:hover {
    background: var(--hover-btn-color) !important;
  }
}

.nothing-box {
  text-align: center;
  @include center;

  .nothing-icon {
    @include wh(200px, 200px);
    display: flex;
  }

  .nothing-text {
    @include button-basic;
    font-size: 20px;
    color: #666666;
    line-height: 23px;
    padding-top: 8px;
  }
}

.search-count {
  @include button-basic;
  font-size: 14px;
  color: #cdcdcd;
  line-height: 16px;
  padding-bottom: 8px;
}

.works-title-box {
  align-items: flex-end;
  padding: 24px 0 9px;

  .works-title {
    @include button-basic-SemiBold;
    font-size: 32px;
    line-height: 38px;
  }

  .works-quantity {
    @include button-basic-Medium;
    font-size: 20px;
    line-height: 23px;
    padding-left: 8px;
  }
}

.none-works-box {
  text-align: center;
  @include center;

  .none-works {
    @include wh(200px, 200px);
  }

  .none-works-text {
    @include button-basic-Medium;
    font-size: 20px;
    color: #666666;
    line-height: 23px;
    padding-top: 8px;
  }
}


.payments {
  @include D-DIN-PRO-Medium;
  font-size: 16px;
  color: #CDCDCD;
  line-height: 22px;
  padding: 32px 0;

  .symbol {
    color: #FFFFFF;
    padding-left: 12px;
  }

  .amount {
    color: #FFFFFF;
    font-size: 40px;
    line-height: 46px;
  }
}

.el-cascader__dropdown {
  background: #333333 !important;
  border: none !important;

  .popper__arrow {
    display: none !important
  }

  .el-cascader-menu {
    @include button-basic;
    width: 212px;
    height: 188px;
    font-size: 14px;
    color: #CDCDCD;
    line-height: 22px;
    border-right: solid 1px #414141;

    .el-cascader-menu__list {
      padding: 8px;

      .el-cascader-node {
        width: 196px;
        padding: 0;
        height: 38px;
      }
    }

    .el-cascader-node.in-active-path {
      background: #414141 !important;
      border-radius: 4px;
      color: #F06B0A;
    }

    .el-cascader-node.is-active {
      background: #414141 !important;
      border-radius: 4px;
      color: #F06B0A;
    }

    .el-cascader-node:not(.is-disabled) {
      background: none;
    }

    .el-cascader-node:not(.is-disabled):hover {
      width: 196px;
      height: 38px;
      background: #414141 !important;
      border-radius: 4px;
    }
  }
}

.e-edit-btn {
  width: 131px;
  height: 48px;
  background: var(--primary-btn-color);
  color: #ffffff !important;
  border-radius: 8px 8px 8px 8px;
  @include button-basic;
  @include flex;
  cursor: pointer;

  // margin-bottom: 60px;
  &:hover {
    background: var(--hover-btn-color);
  }
}

.footer-boxs {
  width: 100%;
  height: 231px;
  background: #1C1C1C;
  position: relative;
  padding: 36px 116px;
  box-sizing: border-box;

  .left-box {
    flex: 1;
    min-width: 290px;

    .logos {
      @include wh(195px, 48px);
      margin-bottom: 32px
    }

    .footer-text {
      @include button-basic-Light;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 23px;
    }
  }

  .right-box {
    display: flex;
    justify-content: center;
    @include button-basic-Light;
    min-width: 500px;

    .agreement {
      color: #FFFFFF;
      padding-bottom: 24px;
    }

    .agreement-text {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      color: #999999;

      span {
        cursor: pointer;

        &:hover {
          color: #cdcdcd;
        }
      }
    }

    .contact {
      padding-left: 32px;

      .weixin {
        @include wh(72px, 72px);
        margin-bottom: 20px;
      }
    }


    .cooperate {}
  }

}

.footer {
  width: 100%;
  min-width: 1000px;
  height: 41px;
  text-align: center;
  gap: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    @include button-basic;
    font-size: 14px;
    color: #666666;
    line-height: 16px;
  }

  .icon-g {
    width: 15px;
    height: 16px;
    padding-right: 8px;
  }

  .icp {
    cursor: pointer;

    &:hover {
      color: #999999;
    }
  }
}

.el-image-viewer__wrapper {
  .el-image-viewer__mask {
    opacity: .9 !important;
  }
}
</style>
